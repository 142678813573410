import TailwindLoader from '../../Components/TailwindLoader';
import Header from '../../Layouts/Aps/Header';
import ScrollToTop from '../../Components/ScrollToTop';
import Footer from '../../Layouts/Aps/Footer';
import CatalogJumbotron from '../../Layouts/Aps/CatalogJumbotron';
import ProductCatalog from '../../Layouts/Aps/ProductCatalog';

function ApsCatalogPage() {
    return (
        <TailwindLoader>
            <Header active="catalog" />
            <CatalogJumbotron />

            <ProductCatalog />

            <div className="my-28"></div>

            <Footer withGetInTouch={false} />
            <ScrollToTop />
        </TailwindLoader>
    );
}

export default ApsCatalogPage;