import axios from 'axios';
import useSWR from 'swr/immutable';
import yaml from 'js-yaml';

export function useCatalog() {
    const { data, isLoading, error } = useSWR(
        '/catalog.yaml',
        (url) => axios.get(url).then((response) => response.data)
    );

    const output = {
        data: null,
        isLoading,
        error,
    };

    if (data) {
        try {
            output.data = yaml.load(data);
        } catch (e) {
            output.error = e;
        }
    }

    return output;
}