import { useCatalog } from '../../Hooks/useCatalog';
import { useEffect, useMemo, useState } from 'react';

function ProductCatalog() {
    const products = useCatalog();
    const [allowShow, setAllowShow] = useState(false);

    // Lazy-show
    useEffect(() => {
        const timer = setTimeout(() => {
            setAllowShow(true);
        }, 700);

        return () => clearTimeout(timer);
    }, []);

    if (products.isLoading || !allowShow) {
        return (
            <CatalogSkeleton />
        );
    }

    if (products.error) {
        return (
            <div>Halaman gagal dimuat.</div>
        );
    }

    return (
        <div className="max-w-[70rem] px-2 mx-auto my-14 grid grid-cols-2 md:grid-cols-4 gap-2 md:gap-8">
            {
                products.data.map((product, index) => {
                    return (
                        <ProductItem key={index} product={product} />
                    );
                })
            }
        </div>
    );
}

function ProductItem({ product }) {
    const formattedPrice = useMemo(() => {
        const formattedCurrency = new Intl.NumberFormat('id-ID', {
            style: 'currency',
            currency: 'IDR',
          }).format(product.price);
          
          // Replace the default "Rp" formatting with "Rp " (adding a space)
          const formattedWithSpace = formattedCurrency.replace('Rp', 'Rp ');

          return formattedWithSpace;
    }, [product.price]);

    return (
        <div className="transition rounded-lg md:hover:shadow-xl md:hover:border md:hover:scale-110">
            <div className="w-full aspect-square group-hover:shadow-2xl">
                <img src={`/assets/img/products/${product.image}`} className="size-full object-contain" />
            </div>
            <div className="px-2 pb-4">
                <h3 className="text-center !not-italic text-black text-lg mt-2">{product.title}</h3>
                <p className="text-sm text-center text-gray-500">{product.description}</p>
                <p className="font-semibold text-center">
                    <span className="text-sm text-green-700">{formattedPrice}</span>
                    {product.unit && <span className="text-sm"> / {product.unit}</span>}
                </p>
            </div>
        </div>
    );
}

function ProductItemSkeleton() {
    return (
        <div className="transition rounded-lg">
            <div className="w-full aspect-square bg-gray-200 animate-pulse group-hover:shadow-2xl"></div>
            <div className="px-2 pb-4 my-2 space-y-2">
                <div className="h-[20px] w-full bg-gray-200 animate-pulse rounded-full"></div>
                <div className="h-[18px] w-[80%] mx-auto bg-gray-200 animate-pulse rounded-full"></div>
                <div className="h-[20px] w-[50%] mx-auto bg-gray-200 animate-pulse rounded-full"></div>
            </div>
        </div>
    );
}

function CatalogSkeleton() {
    return (
        <div className="max-w-[70rem] px-2 mx-auto my-14 grid grid-cols-2 md:grid-cols-4 gap-2 md:gap-8">
            <ProductItemSkeleton />
            <ProductItemSkeleton />
            <ProductItemSkeleton />
            <ProductItemSkeleton />
            <ProductItemSkeleton />
            <ProductItemSkeleton />
            <ProductItemSkeleton />
            <ProductItemSkeleton />
            <ProductItemSkeleton />
            <ProductItemSkeleton />
            <ProductItemSkeleton />
            <ProductItemSkeleton />
        </div>
    );
}

export default ProductCatalog;